<template>
    <div class="route-container">
        <van-empty image="search" v-if="humanisticList.length == 0 && load" description="暂无数据" />
        <punch-card
            class="u-m-t-30"
            txtTag="lineTitle"
            imgTag="indexUrl"
            :list="humanisticList"
            :src="'/route/details'"
            v-else
        ></punch-card>
        
    </div>
</template>

<script>
import { getMyRoute } from "@/service";
import punchCard from "@/components/card/punch-card.vue";
export default {
    components: {
        punchCard,
    },
    data() {
        return {
            load: false,
            humanisticList: [],
        };
    },
    mounted() {
        this.getMyRoute();
    },
    methods: {
        async getMyRoute() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getMyRoute(params);
            this.load = true;
            this.humanisticList = data.list || [];
        },
    },
};
</script>

<style lang="scss" scoped>
.route-container {
    padding: 30px 36px 0;
    background: #fff;
}
</style>