<template>
    <nav class="punch-card">
        <div class="item-container" v-if="list.length">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="item-card"
                @click="openPage(item.id)"
            >
                <!-- <div
                    class="punch-img"
                    :style="{
                        background: `url(${item[imgTag]})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center'
                    }"
                ></div> -->
                <van-image class="punch-img" :src="item[imgTag]" fit="cover" radius="10 20" />
                <div class="punch-card-back"></div>
                <div class="punch-content">
                    <figure>
                        <div class="punch-title">{{ item.lineTitle }}</div>
                        <div class="punch-label u-m-t-2">
                            {{ item.lineType }}
                        </div>
                        <figcaption>
                            <div class="u-m-t-34">
                                有效期：{{ item.lineTime }}
                            </div>
                            <div class="u-m-t-14">
                                任务：{{ item.lineTask }}
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "punch-card",
    props: ["list", "imgBaseUrl", "imgTag", "src"],
    components: {},
    data() {
        return {};
    },
    methods: {
        openPage(id) {
            this.$router.push(`${this.src}?id=${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.punch-card {
    background-color: #fff;
    .item-container {
        @include wh(100%, auto);
        padding: 0 0;

        .item-card {
            @include fj(space-between);
            width: 100%;
            text-align: center;
            margin-top: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            position: relative;
            .punch-card-back {
                @include wh(210px, 66px);
                position: absolute;
                top: 90px;
                right: 44px;
                background: #ffd9da;
                z-index: 0;
            }
            .punch-img {
                @include wh(100%, 320px);
                border-radius: 20px 0 0 20px;
                flex: 1;
            }
            .punch-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 240px;
                padding-left: 20px;
                text-align: left;
                position: relative;
                z-index: 1;
                figure {
                    width: 100%;
                    .punch-title {
                        font-weight: bold;
                        @include sc(32px, $--color-text-regular);
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        word-break: break-all; /*追加这一行代码*/
                    }
                    .punch-label {
                        @include sc(24px, $--color-text-primary);
                        white-space: normal;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        /*! autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        word-break: break-all; /*追加这一行代码*/
                        padding-left: 30px;
                    }
                    figcaption {
                        @include sc(20px, $--color-text-regular);
                        overflow-wrap: break-word;
                        white-space: nowrap;
                        line-height: 28px;
                        padding-left: 10px;
                        white-space: normal;
                        text-align: left;
                    }
                }
            }
        }
    }
}
</style>